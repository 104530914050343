import * as React from "react"
// @ts-ignore
import Layout from "../components/layout"
// @ts-ignore
import SEO from "../components/seo"
// @ts-ignore
import ClipPath from "../assets/clipPath.svg"
import { Data, PortfolioCard } from "../components/portfolioCard"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

const PortfolioPage = () => {
  const { allAirtable } = useStaticQuery(
    graphql`
        query {
            allAirtable {
                nodes {
                    data {
                        Name
                        Platform
                        Services
                        Thumbnail {
                            localFiles {
                                childImageSharp {
                                    fluid(maxHeight: 400) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `
  )
  const { nodes }: { nodes: Node[] } = allAirtable

  return (
    <Layout showBlob={false}>
      <SEO title="Portfolio" />
      <div className="content">
        <ClipPath />
        <Container>
          {nodes.map(({ data }, index) => (
            <div key={index}>
              <PortfolioCard {...data} />
            </div>
          ))}
        </Container>
      </div>
    </Layout>
  )
}

interface Node {
  data: Data
}


const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 40px;
  & > div {
    margin: 20px;
  }
`

export default PortfolioPage
